<template>
  <div class="shop">
    <router-view/>
  </div>
</template>

<script>
import Banner from "@/components/Shop/Banner.vue";
export default {
  name: "Shop",
  components: {
    Banner,
  },
  data: () => ({}),
};
</script>
