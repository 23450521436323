<template>
  <div class="banner">
    <v-carousel cycle height="500" hide-delimiters show-arrows-on-hover>
      <v-carousel-item v-for="(item, i) in items" :key="i">
        <v-sheet
          :color="colors[index]"
          height="500"
          v-bind:style="{
            backgroundImage: 'url(' + item.image + ')',
            backgroundSize: 'cover',
          }"
        >
          <v-row class="fill-height" align="center">
            <v-col cols="12" md="6">
              <div class="px-10 py-3">
                <div class="font-weight-bold text-body-1">
                  {{ item.caption }}
                </div>
                <div class="text-h2 font-weight-black">{{ item.title }}</div>
                <div class="row-action mt-5">
                  <v-btn
                    class="pa-0"
                    small
                    text
                    color="red"
                    dark
                    :href="item.link"
                    target="_blank"
                  >
                    Selengkapnya
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      texts: ["First", "Second", "Third", "Fourth", "Fifth"],
      items: [],
    };
  },
  mounted() {
    this.getCarousels();
  },
  methods: {
    getCarousels() {
      axios.get("carousel").then((res) => {
        this.items = res.data.data.carousels["4"];
      });
    },
  },
};
</script>
